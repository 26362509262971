import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/homeContent",
    component: Home,
    children: [
      {
        path: "/homeContent",
        name: "homeContent",
        component: () => import("../components/wikiList.vue"),
      },
      {
        path: "/wikiDetail",
        name: "wikiDetail",
        component: () => import("../components/wikiDetail.vue"),
      },
      {
        path: "/p/:code",
        name: "p",
        component: () => import("../components/p.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
